var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"church-ministry template-1"},[_c('header',{staticClass:"page-header container"},[_c('h1',[_vm._v(_vm._s(_vm.translations.tcChurchMinistry))])]),(!_vm.allowPage)?_c('security-banner',{attrs:{"i18n":_vm.translations.components}}):_vm._e(),(_vm.allowPage)?_c('div',[_c('camp-selector',{attrs:{"tooltip":_vm.translations.tcToolTip,"i18n":_vm.translations.components},on:{"camp_changed":function($event){return _vm.page_load()}}}),_c('div',{staticClass:"page-body container mx-med"},[_c('section',{staticClass:"section-1"},[_c('h4',[_vm._v(_vm._s(_vm.translations.tcRecentChurchVisits))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[(this.visits.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.translations.tcNoChurchVisitsMessage)+" ")]):_c('div',_vm._l((this.visits),function(visit,index){return _c('DateCard',{key:index,attrs:{"obj":visit.date,"i18n":_vm.translations.common}},[(_vm.translations.common)?_c('BasicDateCard',{attrs:{"i18n":_vm.translations.components,"obj":{
                  title: visit.title,
                  description: visit.description,
                  schedule: visit.schedule,
                  contact_type: visit.contactTypeGuid,
                  visit_key: visit.visit_key,
                  entity_key: visit.entity_key,
                  destination: visit.destination,
                }}}):_vm._e()],1)}),1),_c('div',{staticClass:"mt-3"},[(_vm.iCanSee(_vm.secured_note_add_controls.add_church_visit_note_button))?_c('button',{staticClass:"btn btn-primary btn-w-med mt-3 mb-3",on:{"click":_vm.handleMoreVisitClick}},[_vm._v(" "+_vm._s(_vm.translations.tcViewMore)+" ")]):_vm._e(),_vm._v(" "),(_vm.iCanSee(_vm.secured_note_add_controls.add_church_visit_note_button))?_c('button',{staticClass:"btn btn-primary btn-w-med mt-3 mb-3",on:{"click":_vm.handleAddVisitClick}},[_vm._v(" "+_vm._s(_vm.translations.tcAddVisit)+" ")]):_vm._e()])]),_c('div',{staticClass:"col col-6"},_vm._l((_vm.topWidgetData),function(stat,index){return _c('NumberCard',{key:index,attrs:{"obj":stat,"i18n":_vm.translations.components}})}),1)])]),_c('section',{staticClass:"section-2"},[_c('h4',[_vm._v(_vm._s(_vm.translations.tcUpcomingChurchPresentations))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[(this.scheduledPresentations.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.translations.tcNoPresentationsMessage)+" ")]):_c('div',_vm._l((this.scheduledPresentations),function(prs,index){return _c('DateCard',{key:index,attrs:{"obj":prs.date,"i18n":_vm.translations.common}},[(_vm.translations.common)?_c('BasicDateCard',{attrs:{"i18n":_vm.translations.components,"obj":{
                  title: prs.title,
                  description: prs.description,
                  schedule: prs.schedule,
                  churchServiceTypeGuid: prs.churchServiceTypeGuid,
                  weekdayGuid: prs.date.weekdayGuid,
                  presentation_key: prs.presentation_key,
                  destination: prs.destination,
                }}}):_vm._e()],1)}),1),_c('div',{staticClass:"mt-3"},[_c('router-link',{staticClass:"btn btn-primary btn-w-med mr-4 mt-3 mb-3",attrs:{"to":"/programs/cm/presentations"}},[_c('div',[_vm._v(_vm._s(_vm.translations.tcViewMore))])]),_vm._v(" "),(_vm.iCanSee(_vm.secured_presentation_add_controls.add_church_presentation_button))?_c('button',{staticClass:"btn btn-primary btn-w-med mt-3 mb-3",on:{"click":_vm.handleAddPresentationClick}},[_vm._v(" "+_vm._s(_vm.translations.tcAddPresentation)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col col-6"},_vm._l((_vm.bottomWidgetData),function(stat,index){return _c('NumberCard',{key:index,attrs:{"obj":stat,"i18n":_vm.translations.components}})}),1)])]),_c('section',{staticClass:"section-3"},[_c('div',{staticClass:"row"},_vm._l((_vm.churchMinistryInfo),function(info,index){return _c('InfoCard',{key:index,attrs:{"obj":info},on:{"set_program":function($event){return _vm.handleSetProgram($event)}}})}),1)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }